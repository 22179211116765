import React from 'react'

const Home = () => {
  const scollREcent = () => {
    document.getElementById('market_activity').scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  const scollRecent = () => {
    document.getElementById('roadmap').scrollIntoView({ behavior: 'smooth', block: 'end' });
  }
  const scollPillar = () => {
    document.getElementById('pillar').scrollIntoView({ behavior: 'smooth', block: 'end' });
  }
  const scrollTeam = () => {
    document.getElementById('team').scrollIntoView({ behavior: 'smooth', block: 'end' });
  }
  const scrollFaq = () => {
    document.getElementById('faq').scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  return (
    <div>
      <header>
        {/* <div class="brands">
       	 <a class="navbar-brand" href="#">
	    		<img src="img/monstraz.png" alt="monstraz">
	    	</a>
       </div> */}
        <nav className="navbar">
          <div className="container">
            <div className="mobile-logo">
              <a className href="#">
                <img src="img/monstraz.png" alt="monstraz" />
              </a>
            </div>
            <div className="menu-toggle" id="mobile-menu">
              <span className="bar" />
              <span className="bar" />
              <span className="bar" />
            </div>
            <div className="navigation">
              <ul className="navbar-nav">
                <li className="nav-item active_li">
                  <a className="nav-link active" aria-current="page" onClick={scollREcent} href="javascript:void(0)">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" onClick={scollRecent} href="javascript:void(0)">Roadmap</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" onClick={scollRecent} href="javascript:void(0)">Traits</a>
                </li>
                <a className="navbar-brand" href="#">
                  <img src="img/monstraz.png" alt="monstraz" />
                </a>
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" onClick={scollPillar} href="javascript:void(0)">7pillars</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" onClick={scrollTeam} href="javascript:void(0)">Our Team</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" onClick={scrollFaq} href="javascript:void(0)">Faqs</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* Banner Section */}
        <section className="mainbnr">
          <div className="container">
            <div className="row banner-content">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="bnrtext">
                  <h1>Monstarz is<br /> leading NFT’s into<br /> the future!</h1>
                  {/* <p id="introduction_text">Join the Discord to get involved! Our 7 Layers of Utility makes this the best investment in the NFT market…LFG!</p> */}

                  <div className="mint-form">
                    <div className="row justify-content-center">
                      <div className="col-9">
                        <div className="row  align-items-center justify-content-center">
                          <div className="col-3  mb-md-0">
                            <a href="javascript:void(0)" className="mintMinus ">
                              <img src="https://homospacien.io/img/webp/minus.webp" alt="" className="img-fluid" />
                            </a>
                          </div>
                          <div className="col-6 text-center">
                            <div className="mintBuyTotal position-relative">
                              <img src="https://homospacien.io/img/webp/number_wrapper.webp" alt="" className="img-fluid" />
                              <div id className="buyCount position-absolute top-50 start-50 translate-middle">
                                1
                              </div>
                            </div>
                            {/* <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="100" /> */}
                          </div>
                          <div className="col-3 mt-md-0">
                            <a href="javascript:void(0)" id className="mintPlus ">
                              <img src="https://homospacien.io/img/webp/plus.webp" alt="" className="img-fluid" />
                            </a>
                          
                          </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                          <div className="col-auto text-center">
                            <a href="javascript:void(0)" id className="mintBtn btn btn-secondary text-uppercase btn-lg px-5 "><b>Mint now</b></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button className="btn btn-success custombtn">Join The Discord</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Banner Section Exit*/}
      </header>
      {/* Header Section Exit */}
      {/* Monstarz Section */}
      <section className="monstraz pt-5 pb-5" id="market_activity">
        <div className="container monstContainer">
          <div className="row monstraz-content pt-5 pb-5">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="monstraz-left">
                <div className="monstraz-text">
                  <h2>Who are the Monstarz!</h2>
                  <p>We have all heard the story of the cute, cuddly little creatures living on the blockchain, well the word has spread. So far in fact a group of very athletic, very scary beasts have now caught wind. This group apparently goes by the name Monstarz. From all signs it looks like these Monstarz on now on their way to takeover, rule, and dominate the universe as we know it. Word is they are even planning on renaming it the Monstaverse! To this point very little is known of these Monstarz and what they want but from the look of their initial incoming fleet it seems to be 10,000 of them or possibly more. They are currently headed in this direction and few brave enough to go near the Monstarz have said their ships have a huge claw logo that says Monstarz and bare the words “Initial Draft Class.” We can only begin to guess what that means. All that we can say to this point is that they are coming and their takeover seems inevitable.</p>
                  <div className="monstraz-btn-block">
                    <button className="btn btn-success custombtn">Join The Discord</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="monstraz-right">
                <div className="monstraz-img">
                  <img src="img/monst-right.png" alt="monst-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Monstarz Section Exit*/}
      {/* Traits Section */}
      <section className="traits pt-5 pb-5" id="roadmap">
        <div className="container">
          <div className="row traits-head">
            <h2>TRAITS</h2>
            <p>Each member of the Monstarz Original Draft Class is 100% unique and randomly generated from 150 plus traits including famous basketball player and other popular hairstyles, different hair colors, expressions, headwear, accessories, and other very rare attributes that you will just have to wait, mint, and see for yourself!</p>
          </div>
          <div className="row traits-content">
            <div className="col-lg-4 col-md-4 col-12">
              <div className="road-text">
                <h2>ROADMAP<br className='io' />.IO</h2>
                <p>Monstarz Roadmap 1.0 The Original Draft Class</p>
                <div className="monstraz-btn-block">
                  <button className="btn btn-success custombtn">Join The Discord</button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="roadimg1">
                <img src="img/shape1.png" alt="shape1" />
                <div className="smalltext">
                  <h3>20%</h3>
                  <p>Raffle 12 Monstarz to our initial holders</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="roadimg2">
                <img src="img/shape2.png" alt="shape2" />
                <div className="smalltext">
                  <h3>40%</h3>
                  <p className="office">official creation of Monstarz Mutual Fund &amp; 2 initial purchases voted on by the holders.</p>
                </div>
              </div>
            </div>
            <div className="row pt-5 mt-5 pb-4">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="roadimg2">
                  <img src="img/shape2.png" alt="shape2" />
                  <div className="smalltext">
                    <h3>60%</h3>
                    <p className="office">Raffle 12 Monstarz to investors. Announcement of the Monstarz Pass Diamond and Gold. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="roadimg2">
                  <img src="img/shape2.png" alt="shape2" />
                  <div className="smalltext">
                    <h3>80%</h3>
                    <p className="office">Airdrop 10 Monstarz NFT Draft Class into our initial mutual fund. At 80% ownership the talks to buy a professional basketball team will begin between founders and  holders.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="roadimg2">
                  <img src="img/shape2.png" alt="shape2" />
                  <div className="smalltext">
                    <h3>100%</h3>
                    <p className="office">SOLD OUT! Party in Vegas for all holders, 2 more big drops for the mutual fund, announcement of  drop number 2 and  Roadmap 2.0 announcement of our merch stores and how holders will benefit from them. Plus a few more surprises so stay tuned!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Traits Section Exit*/}
      {/* pillars section strats */}
      <section className="pillarimg pb-5" id="pillar">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="pillarText">
              <h2>7 PILLARS OF<br />
                <span>UTILITY</span>
              </h2>
              <p>Monstarz 7 Pillars of Utility</p>
            </div>
          </div>
          <div className="row pt-2 pb-5">
            <div className="col-lg-3 col-md-3 col-12 pill-img">
              <img src="./img/pillar1.png" />
              <div className="pill-text">
                <h3>GAMING/METAVERSE</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 pill-img">
              <img src="./img/pillar2.png" />
              <div className="pill-text">
                <h3>MONSTARZ Billionaire Club</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 pill-img">
              <img src="./img/pillar3.png" />
              <div className="pill-text">
                <h3>PRO BASKETBALL TEAM OWNERSHIP</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 pill-img">
              <img src="./img/pillar4.png" />
              <div className="pill-text">
                <h3>MONSTARZ Black Card</h3>
              </div>
            </div>
          </div>
          <div className="row mt-5 pt-5">
            <div className="col-lg-3 col-md-3 col-12 pill-img offset-md-2">
              <img src="./img/pillar5.png" />
              <div className="pill-text">
                <h3>PERCENTAGE OF MERCH SALES RAFFLED TO HOLDERS</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 pill-img">
              <img src="./img/pillar6.png" />
              <div className="pill-text">
                <h3>FUTURE AIRDROPS FOR HOLDERS AND FIRST ACCESS ON PARTNERSHIPS</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 pill-img">
              <img src="./img/pillar7.png" />
              <div className="pill-text">
                <h3>ACCESS TO EXCLUSIVE GROUP ON UPCOMING INVESTMENTS AND CALLS</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* team section start */}
      <section className="Ourteam pt-5 pb-5" id="team">
        <div className="container">
          <div className="row">
            <div className="team-head">
              <h2>OUR<span> TEAM</span></h2>
            </div>
          </div>
          <div className="row  teamrow">
            <div className="col-lg-3 col-md-3 col-12 teamimg">
              <img src="./img/team1.png" />
              <div className="teamtext">
                <h3>PurpleReign<br />(Jimmy Tuma)</h3>
                <p>OUR FOUNDER, OWNER OF A MARKETING FIRM, LONG TERM CRYPTO/NFT INVESTOR INCLUDING BAYC, DOGE, AND MANY MORE.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 teamimg">
              <img src="./img/team2.jpg" />
              <div className="teamtext">
                <h3>PurpleReign<br />(Jimmy Tuma)</h3>
                <p className="somelarge">PARTNER, FORMER PRO FOOTBALL PLAYER AND NFT INVESTOR</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 teamimg">
              <img src="./img/team3.jpg" />
              <div className="teamtext">
                <h3>PurpleReign<br />(Jimmy Tuma)</h3>
                <p className="somelarge"> NFT EXPERT, MARKET RESEARCH AND DEVELOPMENT, PARTNERSHIPS</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 teamimg">
              <img src="./img/team4.jpg" />
              <div className="teamtext">
                <h3>PurpleReign<br />(Jimmy Tuma)</h3>
                <p className="large"> NATIONAL MEDIA AND MARKETING FIRM</p>
              </div>
            </div>
          </div>
          <div className="row pt-5 pb-5">
            <div className="col-lg-3 col-md-3 col-12 teamimg">
              <img src="./img/team5.jpg" />
              <div className="teamtext">
                <h3>Ozzy Metaverse</h3>
                <p className="somelarge">NFT EXPERT AND MONSTARZ COMMUNITY MANAGER</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 teamimg">
              <img src="./img/team6.jpg" />
              <div className="teamtext">
                <h3>Buffalodangler<br />(Jimmy Perkins)</h3>
                <p className="somelarge"> CRYPTO EXPERT AND MONSTARZ COMMUNITY CONSULTANT</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 teamimg">
              <img src="./img/team7.jpg" />
              <div className="teamtext">
                <h3>JRocketNFT</h3>
                <p className="somelarge"> DISCORD EXPERT, SOLIDITY AND WEB3 DEVELOPMENT</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 teamimg">
              <img src="./img/team8.jpg" />
              <div className="teamtext">
                <h3>Hi-Tek Media</h3>
                <p className="large">INVESTOR AND NATIONAL MEDIA FIRM</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* team section end */}
      {/* faqs section start */}
      <section className="faqs bg-light" id="faq">
        <div className="container pt-5 pb-5">
          <div className="row faqsText">
            <h2>FAQs</h2>
            <p>How to Buy and/or Mint?</p>
          </div>
          <div className=" row lineImg">
            <img src="./img/line.png" />
          </div>
          <div className="row faqspara">
            <p>Buy Cryptocurrency: Join and fund a cryptocurrency marketplace like Coinbase to purchase ETH.</p><p>Get a Cryptocurrency Wallet: Signup for a “Non-custodial” wallet like Metamask or Coinbase Wallet (different from Coinbase) think of Coinbase as the bank and Coinbase Wallet as your bank card, which is where you can store and control your cryptocurrency and digital assets.</p><p>Transfer Your Cryptocurrency: Transfer your ETH coins from Coinbase to your wallet (Metamask or other), which gives you the control to purchase NFTs across the various marketplaces.</p><p>Join the NFT Marketplace Opensea. Which is a marketplace for NFT artwork. You will be asked to sync your digital wallet (Metamask or other) to the site, which will allow you to purchase and then store your digital assets.</p><p>When the time comes to mint from our site you will simply goto the site from our OFFICIAL LINKS only, DO NOT click any other links from anywhere else only OUR discord with OUR links. There you will click to connect your wallet and mint our NFTs where the transfer will happen and you will have Monstarz in your wallet eager and ready to dunk on someone!</p><p>When all assets or minted or if preferred Monstarz will be available on Opensea to purchase directly from your wallet as well. For any further questions please don’t hesitate to ask. Thank you.</p>						</div>
          <div className="row">
            <div className="questions">
              <div className="cards">
                <div className="card_header">
                  <h3 id="faqs_accordion">How i can see my Monstarz NFT?</h3>
                </div>
                <div className="card-body active">
                  <p>You can see your entire collection on Opensea.</p>
                </div>
              </div>
              <div className="cards">
                <div className="card_header">
                  <h3 id="faqs_accordion">How Many Monstarz are in the original Draft Class?</h3>
                </div>
                <div className="card-body">
                  <p> It looks to be 10,000 total supply for the initial draft class collection but we may throw some extras in there, we will see.</p>
                </div>
              </div>
              <div className="cards">
                <div className="card_header">
                  <h3 id="faqs_accordion">I have a problem , what do i do?</h3>
                </div>
                <div className="card-body">
                  <p> Goto our Monstarz discord and create a ticket and we will be right there to support you..</p>
                </div>
              </div>
            </div>
          </div>
        </div></section>
      <section>
        <div className="container">
          <div className="row">
            <iframe height="600px" width="100%" src="https://www.youtube.com/embed/mMBQKKQYYF8?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fmonstarznft.com&widgetid=1" />
          </div>
        </div>
      </section>
      <section className="foot pt-5" style={{ backgroundImage: 'url(D:\monsterznft 11-01-2021\Monstarz_new_piyush_sir\newmonsterz\public\img\footimg_new.jpg)' }}>
        <div className="container">
          <div className="footer">
            <a className="navbar-brand" href="#">
              <img src="img/monstraz.png" alt="monstraz" />
            </a>
            <ul>
              <li>
                <a href><i className="fa fa-facebook-f" style={{ fontSize: '22px' }} /></a>
              </li>
              <li>
                <a href><i className="fa fa-twitter" style={{ fontSize: '22px' }} /></a>
              </li>
              <li>
                <a href><i className="fa fa-linkedin" style={{ fontSize: '22px' }} /></a>
              </li>
              <li>
                <a href><i className="fa fa-instagram" style={{ fontSize: '22px' }} /></a>
              </li>
            </ul>
            <div className="footmenu">
              <ul className="navfoot">
                <li><a href>Home</a></li>
                <li><a href>About</a></li>
                <li><a href>Roadmap</a></li>
                <li><a href>Traits</a></li>
                <li><a href>7 Pillars</a></li>
                <li><a href>Our Team</a></li>
                <li><a href>Faqs</a></li>
              </ul>
              <span className="border" />
              <h6><span>© 2021 MONSTRAZ</span></h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home
