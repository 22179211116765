import logo from './logo.svg';
import './App.css';
import Home from './Component/Home';
import config from './config/config';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
function App() {
  return (
    <div className="App">
      <Home/>
          {/* <Router>
                        <Switch>
                              <Route exact path={config.baseUrl} component={Home} />
                            
                            
                        </Switch>
                  </Router> */}
    </div>
  );
}

export default App;
